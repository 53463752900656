import './App.css';

import { useState, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import emailjs from '@emailjs/browser';
import Paper from 'paper';

import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [page, setPage] = useState("home");
  const [portfolioItem, setPortfolioItem] = useState("");
  const [portfolioOverlay, setPortfolioOverlay] = useState(false);
  
  
  const canvasRef = useRef(null);
  const path = useRef(null);
  const emailForm = useRef();

  useEffect(() => {
    const canvas = canvasRef.current;
    Paper.setup(canvas);
    drawBubbles();
  }, []);

  const drawBubbles = () => {
    let myPath = new Paper.Path();
    path.current = myPath;

    setInterval(() => {
      console.log("A");
      path.current.segments.forEach(function(segment, index) {
        segment.point.y += (Math.random() - 0.5) * 2;
        segment.point.x += (Math.random() - 0.5) * 2;

        if (segment.timeout === undefined) {
          segment.timeout = 100;
        }
        segment.timeout--;
        if (path.current !== undefined && segment.timeout === 0) {
          path.current.removeSegment(index);
        }
      });
    }, 10)

    Paper.view.onMouseMove = (event) => {
      path.current.add(event.point);
      path.current.strokeColor = "#FFC709";
      path.current.strokeWidth = 3;
    };

    Paper.view.draw();
  }

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_shwmzd5', 'template_hosqjeu', emailForm.current, 'T9awbgUQWs9aP-D49')
      .then((result) => {
          console.log(result.text);
          alert("Thanks for your email! I'll get back to you soon.");
      }, (error) => {
          console.log(error.text);
          alert("Something went wrong with your email. Please try again later.");
      });
  };

  return (
    <div className="App" id="bootstrap-overrides" style={{height: window.innerHeight}}>
      <div className="header-menu" style={{position: "fixed", top: "30px", right: "30px", display: "flex", flexDirection: "row", 
                                           justifyContent: "space-between", width: "300px"}}>
        <a href="#" onClick={() => setPage("home")}>Hey!</a>
        <a href="#" onClick={() => setPage("portfolio")}>Portfolio</a>
        <a href="#" onClick={() => setPage("contact")}>Contact</a>
        <div style={{
          position: "fixed", top: "65px", right: "30px", display: "flex", flexDirection: "row",
          justifyContent: "flex-end", width: "300px"
        }}><a href="/unodeej">Game Dev Site</a></div>
      </div>
      
      <div style={{ backgroundColor: "#1F2B37", zIndex: "-2", position: "fixed", top: "0", left: "0", width: "100%", height: "100%"}}></div>
      <div style={{
        color: "#9BF3F0",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height:"100%",
        fontSize: "18px",
        fontFamily: "Catamaran, sans-serif",
        zIndex: "-2",
      }}>
        <canvas ref={canvasRef} style={{ position: "absolute", top: "0px", left: "0px", width: "100%", height: "100%", zIndex: "-1" }}></canvas>
        {
          page === "home" ?
            <>
              <div style={{
                minWidth: "400px",
                maxWidth: "33%",
                textAlign: "center",
                
              }}>
                Hey, it's great to meet you. I'm
              </div>
              <div>
                <h1 style={{margin: "20px", fontSize: "85px", textAlign: "center"}}>David J. Uno</h1>
              </div>
              <div style={{
                minWidth: "400px",
                maxWidth: "33%",
                textAlign: "center"
              }}>
                but you can call me DJ.<br/><br/><br/>
                I’m a full-stack web developer who gets stuff done.<br/><br/>
                I work at a startup, which means that every day I overcome obstacles, wear all the hats, and play as a team. 
                Let’s make the world more connected, more imaginative, and more fun.
              </div>
              <div style={{
                position: "fixed",
                top: "48%",
                left: "83%",
              }}>
                <Button className="buttonPrimary buttonRound" onClick={() => setPage("portfolio")}>{">"}</Button>
              </div>
              <div style={{position: "fixed", top: "58%", left: "81%", fontFamily: "Square Peg, sans-serif",
                           color: "#FFC709", fontSize: "36px", lineHeight: "40px", transform: "rotate(5deg)", }} className="hideMobile">
                Check out some<br/>of my work!
              </div>
            </>
          :
          page === "portfolio" ?
            <>
              {
                portfolioItem !== "" ?
                  <div className="modal" style={{display: "block", backgroundColor: "#DAFFED", margin: "8%", width: "84%", height: "74%", borderRadius: "10px"}}>
                    <Button className="buttonPrimary" onClick={() => setPortfolioItem("")}
                            style={{position: "absolute", top: "0", right: "0", width: "60px", height: "60px", fontSize: "35px"}}>X</Button>
                    {
                      portfolioItem === "medicare-bits" ?
                        <>
                          <h1 style={{color: "#1F2B37", fontSize: "60px", margin: "30px", width: "65%", minWidth: "300px"}}>
                            Connecting potential customers with the data they're looking for.
                          </h1>
                          <div style={{color: "#1F2B37", fontSize: "20px", margin: "30px", width: "65%", minWidth: "300px"}}>
                            <img style={{width: "100%", marginBottom: "20px"}} src="https://unodeej-resources.s3.us-west-1.amazonaws.com/medicare-bits1.png"></img>
                            <b>The problem:</b><br/>
                            <p>Like a lot of startups, we found ourselves with a great product, a large population of potential customers, and a shark-infested sea 
                              of competitors separating the two.</p>
                            <b>The idea:</b><br/>
                            <p>What if we took our database of information containing details for tens of thousands of Medicare plans in the U.S., which had been 
                              painstakingly built by programatically and manually processing thousands of PDF documents, and made that data available to seniors 
                              searching for Medicare info?</p>
                            <b>The solution:</b><br/>
                            <p>I engineered a solution using React.js and Python/Flask to automatically generate tens of thousands of unique webpages to help 
                              people find plans available in their local area. Each page was populated by rich, frequently updated datasets in an easy-to-read, 
                              useful format. A data-driven solution helped us stand out from the competition and connect with a potential client base, and improved 
                              accessibility for a vulnerable population to a system that can often be confusing and difficult to navigate.</p>
                            <p><i>See it in action at <a target="_blank" rel="noopener" href="https://www.medicarebits.com/">https://www.medicarebits.com/</a></i></p>
                          </div>
                        </>
                      : 
                      portfolioItem === "planprovide" ?
                        <>
                          <h1 style={{color: "#1F2B37", fontSize: "60px", margin: "30px", width: "65%", minWidth: "300px"}}>
                            Bringing a billion dollar industry into the digital age.
                          </h1>
                          <div style={{color: "#1F2B37", fontSize: "20px", margin: "30px", width: "65%", minWidth: "300px"}}>
                            <img style={{width: "100%", marginBottom: "20px"}} src="https://unodeej-resources.s3.us-west-1.amazonaws.com/planprovide-1.png"></img>
                            <b>The problem:</b><br/>
                            <p>Of the 62.6 million Americans who are enrolled in a Medicare health insurance plan, most of them had to go through the arduous 
                              and error prone process of filling out, signing, and mailing or faxing a pen-and-paper application to their insurance broker.</p>
                            <b>The idea:</b><br/>
                            <p>Thanks to a recently passed piece of legislation authorizing e-signatures for Medicare applications, a huge demand opened up 
                              for a way to remotely fill out and sign these documents. What if we could empower Medicare brokers with the tools to reach 
                              clients that may have difficulty traveling, using clunky phones or videoconferencing, or just prefer to communicate digitally?</p>
                            <b>The execution:</b><br/>
                            <p>When I joined PlanProvide, it was just an idea. I built a proof of concept to demonstrate how an integration with DocuSign 
                              and PDF documents hosted on AWS could bring a product like this into reality. Since then, I've continued to build out the 
                              product with a robust PostGRES database architecture in Python, a full suite of automated unit tests, a live client session 
                              tool using WebSockets, and more.</p>
                          </div>
                        </>
                      :
                      portfolioItem === "learning-centers" ?
                        <>
                          <h1 style={{color: "#1F2B37", fontSize: "60px", margin: "30px", width: "65%", minWidth: "300px"}}>
                            Helping students get connected to the best resources available.
                          </h1>
                          <div style={{color: "#1F2B37", fontSize: "20px", margin: "30px", width: "65%", minWidth: "300px"}}>
                            <img style={{width: "100%", marginBottom: "20px"}} src="https://unodeej-resources.s3.us-west-1.amazonaws.com/learning-centers-1.png"></img>
                            <b>The problem:</b><br/>
                            <p>The average UCLA student pays over $16,000 per year for student dorm housing, which goes towards not only room and board, 
                              but a variety of student resources as well. One such resource, the UCLA Learning Centers, found that despite the rich array 
                              of programs and services it provided, many students didn't even know it existed.</p>
                            <b>The idea:</b><br/>
                            <p>Working together with a dynamic group of creative students and staff, I came up with a design for a completely revamped 
                              website which, along with a social media campaign and new arts and music programming, would aim to catch students' attention 
                              and draw new interest in the Learning Centers.</p>
                            <b>The execution:</b><br/>
                            <p>I talked with students to get to know more about the services they found interesting, partnered with a photographer 
                            to visually capture each space, catalogued resources available to students, drew up designs and presented them to staff, and 
                            constructed the site using a website builder and custom HTML/CSS.</p>
                          </div>
                        </>
                      : 
                      portfolioItem === "cpat" ?
                        <>
                          <h1 style={{color: "#1F2B37", fontSize: "60px", margin: "30px", width: "65%", minWidth: "300px"}}>
                            Connecting a diverse community of Asian Americans.
                          </h1>
                          <div style={{color: "#1F2B37", fontSize: "20px", margin: "30px", width: "65%", minWidth: "300px"}}>
                            <img style={{width: "100%", marginBottom: "20px"}} src="https://unodeej-resources.s3.us-west-1.amazonaws.com/cpat.png"></img>
                            <p>I worked as a freelance web designer to build this website for the Council for Pacific Asian Theology. See it in 
                              action <a href="http://www.cpatvision.org" target="_blank">here</a>.</p>
                          </div>
                        </>
                      : <></>
                    }
                  </div>
                : <></>
              }
              <div style={{
                display: "block",
                textAlign: "start",
                minWidth: "400px",
                maxWidth: "48%"
              }}>
                <h1>Let's get stuff done.</h1>
                <div>Click on any of my projects below and I'll tell you more about it.</div>
                <div style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: "20px"
                }}>
                  <div className="portfolioItem" style={{borderRadius: "5px", borderColor: "#DAFFED", borderWidth: "10px", 
                                                         borderStyle: "solid", width: "45%", height: "40%", marginBottom: "20px",
                                                         position: "relative", display: "inline-block"}}
                      onClick={() => {
                        setPortfolioItem("medicare-bits");
                      }}>
                    <img style={{width: "100%"}} src="https://unodeej-resources.s3.us-west-1.amazonaws.com/medicare-bits1.png"/>
                    <div class="caption" style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center",
                                                fontSize: "28px", fontWeight: "900"}}>
                      Medicare Bits
                    </div>
                  </div>
                  <div className="portfolioItem" style={{borderRadius: "5px", borderColor: "#DAFFED", borderWidth: "10px", 
                                                        borderStyle: "solid", width: "45%", height: "40%", marginBottom: "20px",
                                                        position: "relative", display: "inline-block"}}
                        onClick={() => {
                          setPortfolioItem("planprovide");
                        }}>
                      <img style={{width: "100%"}} src="https://unodeej-resources.s3.us-west-1.amazonaws.com/planprovide-1.png"/>
                      <div class="caption" style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center",
                                                  fontSize: "28px", fontWeight: "900"}}>
                        PlanProvide
                      </div>
                  </div>
                  <div className="portfolioItem" style={{borderRadius: "5px", borderColor: "#DAFFED", borderWidth: "10px", 
                                                        borderStyle: "solid", width: "45%", height: "40%", marginBottom: "20px",
                                                        position: "relative", display: "inline-block"}}
                        onClick={() => {
                          setPortfolioItem("learning-centers");
                        }}>
                      <img style={{width: "100%"}} src="https://unodeej-resources.s3.us-west-1.amazonaws.com/learning-centers-1.png"/>
                      <div class="caption" style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center",
                                                  fontSize: "28px", fontWeight: "900"}}>
                        UCLA Learning Centers
                      </div>
                  </div>
                  <div className="portfolioItem" style={{borderRadius: "5px", borderColor: "#DAFFED", borderWidth: "10px", 
                                                        borderStyle: "solid", width: "45%", height: "40%", marginBottom: "20px",
                                                        position: "relative", display: "inline-block"}}
                        onClick={() => {
                          setPortfolioItem("cpat");
                        }}>
                      <img style={{width: "100%"}} src="https://unodeej-resources.s3.us-west-1.amazonaws.com/cpat.png"/>
                      <div class="caption" style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center",
                                                  fontSize: "28px", fontWeight: "900"}}>
                        Council for Pacific Asian Theology
                      </div>
                  </div>
                </div>
              </div>
              <div style={{
                position: "fixed",
                top: "48%",
                left: "17%",
              }}>
                <Button className="buttonPrimary buttonRound" onClick={() => setPage("home")}>{"<"}</Button>
              </div>
              <div style={{
                position: "fixed",
                top: "48%",
                left: "83%",
              }}>
                <Button className="buttonPrimary buttonRound" onClick={() => setPage("contact")}>{">"}</Button>
              </div>
            </>
          : page === "contact" ?
            <>
              <div style={{
                display: "block",
                textAlign: "start",
                minWidth: "400px",
                maxWidth: "48%"
              }}>
                <h1>Tell me about you!</h1>
                <div>I'd love to hear more about you, too. Want to collaborate on a project, share something interesting, or just say hi?
                  Drop your name and message in the form below, or shoot me an email at david.jarrett.uno@gmail.com.
                </div>
                <form onSubmit={(e) => sendEmail(e)} ref={emailForm}
                      style={{display:"flex", flexDirection: "column", marginTop: "20px"}}>
                  <input type="text" name="user_name" placeholder="Your name" required
                            style={{width: "300px", marginBottom: "5px"}}/>
                  <input type="email" name="user_email" placeholder="Your email" required
                            style={{width: "300px", marginBottom: "5px"}}/>
                  <textarea name="message" placeholder="Your message" required
                            style={{marginBottom: "5px"}}/>
                  <Button className="buttonPrimary" type="submit"
                            style={{width: "100px", marginBottom: "5px"}}>Send</Button>
                </form>
              </div>
              <div style={{
                position: "fixed",
                top: "48%",
                left: "17%",
              }}>
                <Button className="buttonPrimary buttonRound" onClick={() => setPage("portfolio")}>{"<"}</Button>
              </div>
            </>
          : <></>
        }
        
      </div>
    </div>
  );
}

export default App;
